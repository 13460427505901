import React, { useState } from "react";
import { observer } from "mobx-react";
import myYup from "../utils/myYup";
import { useFormik } from "formik";
import Input from "../components/Input";
import { Check, CheckCircle, Feather } from "react-feather";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import UserStore from "../stores/UserStore";
import LoadingStore from "../stores/LoadingStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";


const RegisterPage = observer((props) => {

    const { idRes, idCom } = useParams()
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const steps = ['Authentification', 'Création carte', 'Récap']

    const [emailChecked, setEmailChecked] = useState(false)
    const [accountExists, setAccountExists] = useState(false)
    const [redirect, setRedirect] = useState(null)

    const {
        values,
        setFieldValue,
        errors,
        handleSubmit,
        resetForm
    } = useFormik({
        initialValues: {
            action: 'register',
            gender: null,
            type: null,
            email: '',
            nom: '',
            prenom: '',
            tel: '',
            date_naiss: '',
            cp: '',
            ville: '',
            pass: '',
            c_pass: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: myYup.object().shape({
            gender: myYup.number().required().nullable(true),
            nom: myYup.string().required(),
            prenom: myYup.string().required(),
            email: myYup.string().email().required(),
            date_naiss: myYup.string().required(),
            tel: myYup.string().required().matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres")
                .min(10, 'Le numéro doit contenir 10 chiffres')
                .max(10, 'Le numéro doit contenir 10 chiffres'),
            cp: myYup.string().required().matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres"),
            ville: myYup.string().required(),
            pass: myYup.string().required(),
            c_pass: myYup.string()
                .label('confirmation mot de passe')
                .required()
                .oneOf([myYup.ref('pass'), null], 'Les mots de passe ne correspondent pas.'),
        }),
        onSubmit: async (values) => {
            await UserStore.register(values)

        },
    });

    const login = useFormik({
        initialValues: {
            action: 'login',
            email: '',
            pass: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: myYup.object().shape({
            email: myYup.string().email().required(),
            pass: myYup.string().required(),
        }),
        onSubmit: async (values) => {
            const res = await UserStore.login(values)
        },
    });

    const goAfterCheckEmail = async (email) => {
        LoadingStore.on()
        const emailExists = await UserStore.checkIfAccountExists(email)

        setEmailChecked(true)

        if (emailExists) {
            setAccountExists(true)
        } else {
            setAccountExists(false)
        }

        const from = searchParams.get('tpefrom')

        if (from) {
            setRedirect('?tpefrom=' + from)
        }

        LoadingStore.off()
    }

    const goToRegister = () => {
        resetForm()
        setAccountExists(false)
        setEmailChecked(false)
    }

    return (

        <div className="register-page ac jc"  style={{ height: "90vh" }}>
            <div className="login-box mt-5 px-2">
                <div className="card shadow-lg">
                    {/* <div className="d-flex justify-content-between card-body">
                        {
                            steps.map((item) =>
                                <div className="text-sm" style={{fontSize : '.9rem'}}>
                                    <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2"></FontAwesomeIcon>
                                    {item}
                                </div>)
                        }
                    </div> */}
                    {!emailChecked &&
                        <div className="card-body">
                            <h4 className="">Création compte FIDLINK</h4>
                            <p className="">Veuillez saisir votre adresse Email</p>
                            <div className="form-group floated mb-2">
                                <label>Email</label>
                                <Input
                                    name="email"
                                    type="email"
                                    onChange={setFieldValue}
                                    value={values["email"]}
                                    className={"form-control"}
                                    error={errors["email"]}
                                />
                            </div>
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={() => goAfterCheckEmail(values['email'])}
                                >
                                    <span className="me-2">Continuer</span>

                                </button>
                            </div>
                        </div>
                    }
                    {emailChecked && !accountExists &&
                        <div className="card-body">
                            <h4 className="">Création compte</h4>
                            <p className="">Veuillez compléter le formulaire ci-dessous afin de créer votre carte de fidélité</p>
                            <div className="d-flex mb-2 jc">
                                <div className="form-group  me-5">
                                    <Input
                                        name="gender"
                                        id={"gender1"}
                                        label="Homme"
                                        type="radio"
                                        onChange={() => setFieldValue('gender', 1)}
                                        isChecked={values["gender"] == 1}
                                        value={1}
                                        className={"form-control"}
                                        error={errors["gender"]}
                                    />
                                </div>
                                <div className="ms-5  form-group">
                                    <Input
                                        name="gender"
                                        id={"gender2"}
                                        label="Femme"
                                        type="radio"
                                        onChange={() => setFieldValue('gender', 2)}
                                        isChecked={values["gender"] == 2}
                                        value={2}
                                        className={"form-control"}
                                        error={errors["gender"]}
                                    />
                                </div>
                            </div>
                            {
                                !!errors["gender"] &&
                                <div className="w-100 invalid-feed mb-2">
                                    {errors["gender"]}
                                </div>
                            }
                            <div className="form-group floated mb-2">
                                <label>Nom</label>
                                <Input
                                    name="nom"
                                    type="text"
                                    onChange={setFieldValue}
                                    value={values["nom"]}
                                    className={"form-control"}
                                    error={errors["nom"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Prénom</label>
                                <Input
                                    name="prenom"
                                    type="text"
                                    onChange={setFieldValue}
                                    value={values["prenom"]}
                                    className={"form-control"}
                                    error={errors["prenom"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Email</label>
                                <Input
                                    name="email"
                                    type="email"
                                    disabled={true}
                                    onChange={setFieldValue}
                                    value={values["email"]}
                                    className={"form-control"}
                                    error={errors["email"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Date de naissance</label>
                                <Input
                                    name="date_naiss"
                                    type="date"
                                    onChange={setFieldValue}
                                    value={values["date_naiss"]}
                                    className={"form-control"}
                                    error={errors["date_naiss"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>N° Mobile</label>
                                <Input
                                    name="tel"
                                    type="text"
                                    onChange={setFieldValue}
                                    value={values["tel"]}
                                    className={"form-control"}
                                    error={errors["tel"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Adresse</label>
                                <Input
                                    name="adresse"
                                    type="text"
                                    onChange={setFieldValue}
                                    value={values["adresse"]}
                                    className={"form-control"}
                                    error={errors["adresse"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Code postal</label>
                                <Input
                                    name="cp"
                                    type="text"
                                    onChange={setFieldValue}
                                    value={values["cp"]}
                                    className={"form-control"}
                                    error={errors["cp"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Ville</label>
                                <Input
                                    name="ville"
                                    type="text"
                                    onChange={setFieldValue}
                                    value={values["ville"]}
                                    className={"form-control"}
                                    error={errors["ville"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Mot de passe</label>
                                <Input
                                    name="pass"
                                    type="password"
                                    onChange={setFieldValue}
                                    value={values["pass"]}
                                    className={"form-control"}
                                    error={errors["pass"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Confirmation mot de passe</label>
                                <Input
                                    name="c_pass"
                                    type="password"
                                    onChange={setFieldValue}
                                    value={values["c_pass"]}
                                    className={"form-control"}
                                    error={errors["c_pass"]}
                                />
                            </div>
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={handleSubmit}
                                >
                                    <span className="me-2">Valider</span>

                                </button>
                            </div>
                        </div>
                    }
                    {
                        emailChecked && accountExists &&
                        <div className="card-body d-flex flex-column ac">
                            <CheckCircle className="text-success" size={100}></CheckCircle>
                            <h4 className="mt-3 ">Compte existant</h4>
                            <p className="">Vous possédez déjà un compte FIDLINK</p>
                            <p className="">Veuillez vous connecter s'il vous plaît.</p>

                            <div className="form-group floated mb-3 w-100">
                                <label>Login</label>
                                <Input
                                    name="email"
                                    type="text"
                                    className="form-control"
                                    onChange={login.setFieldValue}
                                    value={login.values['email']}
                                    error={login.errors['email']}
                                />
                            </div>
                            <div className="form-group floated mb-3 w-100">
                                <label>Mot de passe</label>
                                <Input
                                    name="pass"
                                    type="password"
                                    className="form-control"
                                    onChange={login.setFieldValue}
                                    value={login.values['pass']}
                                    error={login.errors['pass']}
                                />
                            </div>
                            <div className="form-group mt-3 w-100">
                                <div className="col-12">
                                    <button onClick={login.handleSubmit} className="btn btn-secondary w-100">Se connecter</button>
                                </div>
                            </div>
                            <div className="form-group mt-3 w-100">
                                <div className="col-12 h4 mb-0 text-center">
                                    ou
                                </div>
                            </div>
                            <div className="form-group mt-3 w-100">
                                <div className="col-12">
                                    <button onClick={() => goToRegister()} className="btn btn-secondary w-100">
                                        <span className="ms-2">Créer un nouveau compte</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
})

export default RegisterPage