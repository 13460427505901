
import React, { useState } from 'react'
import { ChevronDown, Lock, LogOut, Menu, User } from 'react-feather'
import { NavLink, Link, withRouter } from 'react-router-dom'
import logo from '../assets/img/logo_side_fidlink_blanc.png'
import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem
} from 'reactstrap'
import AppMenu from './AppMenu'
import { observer } from 'mobx-react'


const NavbarLayout = observer((props) => {

    const { children } = props
    // console.log(props)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const [isOpen, setIsOpen] = useState(false);
    const toggleNav = () => setIsOpen(!isOpen);


    return (

        <>
            <Navbar className="bg-gradient-orange" expand="lg" container="sm">
                
                    <NavLink to="/" className="brand-link text-center">
                        <img src={logo} alt="main_logo" className="main-logo" />
                    </NavLink>
                    <NavbarToggler onClick={toggleNav}>
                        <Menu size="30" color="#fff"></Menu>
                    </NavbarToggler>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="me-auto" navbar>
                            {/* <AppMenu></AppMenu> */}
                        </Nav>
                        <Nav className="ms-auto" navbar>
                            <li className="nav-item">
                                <NavLink to={{pathname : '/logout'}} className="nav-link" activeClassName="active">
                                    <LogOut size="18" className="mr-2"></LogOut>
                                    Se déconnecter
                                </NavLink>
                            </li>
                        </Nav>
                    </Collapse>
               
            </Navbar>

            <div className="container">
                {children}
            </div>
        </>

    )

})


export default NavbarLayout