import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BottomTabs from "../components/BottomTabs";
import UserStore from "../stores/UserStore";
import { Hash, List, Plus, ShoppingBag, Terminal, X } from "react-feather";
import moment from "moment/moment";
import 'moment/locale/fr';
import { currencyFormat, formatCardNumber, numberFormat } from "../utils/utils";
import DashboardStore from "../stores/DashboardStore";
import { QRCodeSVG } from 'qrcode.react';
import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Input from "../components/Input";
import modalAlert from "../components/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { number } from "../utils/numeral";

moment.locale('fr');

const DetailCardModal = (props) => {

  const { isOpen, toggle, item } = props

  const qrContent = '{"card":"' + item.NumCarte + '"}'

  return (


    <Modal isOpen={isOpen} centered={true} >
      {
        isOpen &&
        <ModalBody className="px-3 py-3">
          <div className="d-flex flex-column w-100 ac jc">
            <div className="grid-card-detail mt-3">
              <div className="border-bottom mb-0 ps-1">Enseigne</div>
              <div className="border-bottom text-secondary ps-3">{item.NomCommercant}</div>
              <div className="border-bottom mb-0 ps-1">N° Carte</div>
              <div className="border-bottom text-secondary ps-3">{formatCardNumber(item.NumCarte)}</div>
            </div>
            <div className="mt-3 d-flex flex-column ac jc  w-100 mb-3">
              <h5>Scanner votre code</h5>
              <div className="shadow-sm border rounded p-3">
                <QRCodeSVG size={250} value={qrContent} />,
              </div>
            </div>
            <div className="mt-3 d-flex flex-column ac jc w-100">
              <button onClick={() => toggle()} className="btn btn-secondary  w-100">Fermer</button>
            </div>
          </div>


        </ModalBody>
      }
    </Modal >
  )
}

const AddCardModal = observer((props) => {

  const navigate = useNavigate()
  const { isOpen, toggle, item } = props
  const [com, setCom] = useState(null);

  useEffect(() => {
    DashboardStore.fetchAllCommercants()
  }, [])

  const onSubmit = () => {
    if (com)
      navigate('/newCard/' + com.id)
    else
      modalAlert({
        title: 'Alerte',
        text: 'Vous devez sélectionner une enseigne',
        typeAlert: 'warning'
      })
  }

  return (

    <Modal isOpen={isOpen} centered={true} >
      {
        isOpen &&
        <ModalBody className="px-3 py-3">
          <div className="d-flex flex-column w-100 ac jc">
            <div className="mt-3 d-flex flex-column w-100">
              <h4>Création carte</h4>
              <p>Veuillez sélectionner l'enseigne pour laquelle vous souhaitez créer votre carte :</p>
            </div>
            <div className="mt-3 d-flex flex-column ac jc  w-100 mb-3">
              <Input
                name="idCom"
                type="search_select"
                onChange={(name, val) => setCom(val)}
                value={com}
                list={DashboardStore.props.shops}
                className={"w-100"}
                error={false}
              ></Input>
            </div>
            <div className="mt-3 d-flex flex-column ac jc w-100">
              <button onClick={() => onSubmit()} className="btn btn-primary  w-100">Valider</button>
            </div>
            <div className="mt-3 d-flex flex-column ac jc w-100">
              <button onClick={() => toggle()} className="btn btn-secondary  w-100">Fermer</button>
            </div>
          </div>


        </ModalBody>
      }
    </Modal >
  )
})

const MainPage = observer((props) => {

  const [isOpenDetailCardModal, setIsOpenDetailCardModal] = useState(false)
  const [isOpenAddCardModal, setIsOpenAddCardModal] = useState(false)
  const [currItem, setCurrItem] = useState(false)

  const lastActivity = [
    {
      created_at: '2022-12-10',
      total_ttc_txt: 12.00,
      commercant: { nom: 'TEST' }
    }
  ]

  const showCardDetail = (item) => {
    setCurrItem(item)
    setIsOpenDetailCardModal(true)
  }

  useEffect(() => {
    DashboardStore.getLastCardsUsed()
    DashboardStore.getLastTransactions()
  }, [])

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-12">
              <h4>Bonjour, {UserStore.props.user.prenom}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="card card-home">
                <div className="px-3">
                  <div className="row">
                    <div className="col-12">
                      <h6 className="text-center">Pour plus de facilité, télécharger l'appli FIDLINK</h6>
                      <div className="row">
                        {/* <!-- App Store button --> */}
                        <div className="col-12 col-md-6 text-center mb-3">
                          <a href="https://apps.apple.com/fr/app/fidlink/id1299912926" target="_blank" class="market-btn apple-btn" role="button">
                            <span class="market-button-subtitle">Télécharger sur</span>
                            <span class="market-button-title">App Store</span>
                          </a>
                        </div>

                        {/* <!-- Google Play button --> */}
                        <div className="col-12  col-md-6  text-center">
                          <a href="https://play.google.com/store/apps/details?id=com.novatecweb.fidlink" target="_blank" class="market-btn google-btn" role="button">
                            <span class="market-button-subtitle">Télécharger sur</span>
                            <span class="market-button-title">Google Play</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="card card-home">
                {/* <LoadingOverlay loading={cardTotalIsLoading}></LoadingOverlay> */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="w-100 d-flex sb">
                        <div className="d-flex ac jc">
                          <List className="text-primary me-2"></List>
                          <h5 className="mb-0"> Mes cartes</h5>
                        </div>
                        <div className="d-flex ac jc">

                          {/* <button onClick={() => setIsOpenAddCardModal(true)} className="btn btn-sm btn-secondary">
                            <Plus size={20} className="me-1"></Plus>
                            Créer une carte
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {
                      DashboardStore.props.lastCardsUsed.map((item) =>
                        <div className="col-12 col-sm-12 mb-3">
                          <div className="card border d-flex flex-row ac" style={{ minHeight: '130px' }}>
                            {/* <div className="me-3 d-flex ac jc">
                              <img src={chipIcon} alt="" className="chip-icon" />
                            </div> */}
                            <div className="d-flex flex-column ac w-100 pe-3">
                              <div className="d-flex ac w-100 mb-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.3" stroke="currentColor" className="w-6 h-6 text-primary me-2">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                                </svg>
                                <h6 className="mb-0 fw-bold text-secondary">{item.NomCommercant}</h6>
                              </div>
                              <div className="d-flex ac w-100 mb-4">
                                <Hash className="text-primary me-2" size={18}></Hash>
                                <h6 className="mb-0 fw-bold text-secondary">{formatCardNumber(item.NumCarte)}</h6>
                              </div>
                              {/* <hr className="w-100" /> */}
                              <table className="table border-top">
                                <tr>
                                  <td className="font-sm">Type carte</td>
                                </tr>
                                <tr>
                                  <td className="border-bottom">
                                    {
                                      parseInt(item.is_virtual) > 0 &&
                                      <span className="fw-bolder">Carte virtuelle</span>
                                    }
                                    {
                                      parseInt(item.is_virtual) == 0 &&
                                      <span className="fw-bolder">Carte physique</span>
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-sm">Code PIN débit</td>
                                </tr>
                                <tr>
                                  <td className="border-bottom">
                                    <span className="fw-bold">{item.pin}</span>
                                  </td>
                                </tr>
                              </table>

                              <div className="d-flex sb w-100">
                                {
                                  (item.mode == 1 || item.mode == 3) &&
                                  <span className="fw-bold">{numberFormat(item.Pme1)} {item.NomUnitePME1}{parseFloat(item.Pme1) != 1 ? 's' : ''}</span>
                                }
                                {
                                  (item.mode == 2 || item.mode == 3) &&
                                  <span className="fw-bold">{numberFormat(item.Pme2)} {item.NomUnitePME2}{parseFloat(item.Pme2) != 1 ? 's' : ''}</span>
                                }
                              </div>
                            </div>
                            {
                              parseInt(item.is_virtual) > 0 &&
                              <div className="d-flex flex-column ac ps-2" style={{ width: '50px' }}>
                                <button className="btn btn-sm btn-secondary" onClick={() => showCardDetail(item)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                                  </svg>

                                </button>
                              </div>
                            }
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-12">
              <h5 className="text-primary">Activité récente</h5>
              <table className="table w-100">
                {
                  lastActivity && lastActivity.length == 0 &&
                  <h3 className="text-center">Aucun ticket disponible</h3>
                }
                {
                  DashboardStore.props.lastTransacs.map((item) =>
                    <tr>
                      <td>
                        <div className="d-flex ac jc">
                          <div className="circle-store me-2">
                            <FontAwesomeIcon icon={faStore} size="lg"></FontAwesomeIcon>
                          </div>
                          {item.NomCommercant}
                        </div>
                      </td>
                      <td>{moment(item.DateTransaction).fromNow()}</td>
                      <td className={(item.TypeTransac == 'Crédit' ? "text-success" : item.TypeTransac == 'Débit' ? "text-danger" : '') + ' fw-bold'}>
                        {item.TypeTransac == 'Crédit' ? '+' + number(item.MontantTransac) + ' ' + (item.unites) : ''}
                        {item.TypeTransac == 'Débit' ? '-' + number(item.MontantTransac) + ' ' + (item.unites) : ''}
                        {item.TypeTransac == 'Initialisation' ? 'Initialisation' : ''}
                      </td>
                    </tr>
                  )
                }

              </table>
            </div>
          </div>
        </div>
        {/* <BottomTabs></BottomTabs> */}
      </div>
      <DetailCardModal
        isOpen={isOpenDetailCardModal}
        item={currItem}
        toggle={() => setIsOpenDetailCardModal(false)}
      ></DetailCardModal>
      {
        isOpenAddCardModal &&
        <AddCardModal
          isOpen={isOpenAddCardModal}
          toggle={() => setIsOpenAddCardModal(false)}
        ></AddCardModal>
      }
    </>
  );
});

export default MainPage;
