import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Navigate, Route, useLocation, withRouter } from 'react-router-dom'
import UserStore from '../stores/UserStore'

const IsAuthenticated = observer((props) => {

    const isAuth = UserStore.props.user.isAuthenticated
    const { children } = props

    console.log('Middleware Is Authenticated', isAuth)


    if (isAuth) {
        return children
    } else {

        return <Navigate to='/login'/>
    }

})

export default IsAuthenticated