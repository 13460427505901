import { observable, set, toJS } from 'mobx'
import api from '../utils/api'
import modalAlert, { modalInfo } from '../components/Alert'
import moment from 'moment/moment'


const props = {
    pageReady: false,
    activationCode: '',
    numCarte: '',
    lastCardsUsed: [],
    lastTransacs: [],
    shops: [],
}



const DashboardStore = observable({
    /***** Props ******/
    props: props,

    /***** Methods ******/

    async getLastCardsUsed() {

        const res = await api.post('', {
            action: 'getLastCardsUsed',
        })

        if (res.data && res.data.success) {

            this.props.lastCardsUsed = res.data.data

        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }
    },
    async getLastTransactions() {

        const res = await api.post('', {
            action: 'getLastTransactions',
        })

        if (res.data && res.data.success) {

            this.props.lastTransacs = res.data.data

        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }
    },
    async fetchAllCommercants(search = null, reseau = null) {

        const res = await api.post('', {
            action: 'fetchAllCommercants',
        })
        if (res.data && res.data.success) {
            // LoadingStore.off()
            this.props.shops = res.data.shops.map((item => ({
                id: item.id,
                label: item.nom,
                ...item
            })))
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return {}
        }
    },

})

export default DashboardStore