import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { unmountComponentAtNode, render } from 'react-dom';
import parse from 'html-react-parser';

const CodePin = (props) => {

    const { title, text, close, resend,edit, value, setValue, handleSubmit } = props

    // const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);

    const onPress = (val) => {
        if (val == 'correct') {
            if (value.length > 0)
                setValue(value.substring(0, value.length - 1))
            else
                console.log('not enought', value, " length : " + value.length)
        }
        else if (val == 'erase')
            setValue('')
        else
            if (value.length < 4)
                setValue("" + value + val)
            else
                console.log('too much', value, " length : " + value.length)
    }

    return (
        <div>
            <div className="d-flex flex-column mb-3">
                <p>Afin d'activer votre carte, veuillez saisir le code à 4 chiffres reçu par SMS. </p>
                <p>Vous n'avez pas reçu le code ? </p>
                <p>
                    <a
                        href="#"
                        onClick={(e) => { e.preventDefault(); resend() }}>
                        Renvoyer le code.
                    </a>
                </p>
                <p>
                    <a
                        href="#"
                        onClick={(e) => { e.preventDefault(); edit() }}>
                       Je modifie mon numéro de téléphone.
                    </a>
                </p>
            </div>
            <div className="d-flex jc mb-4">
                <div className="otp-input">{value[0]}</div>
                <div className="otp-input">{value[1]}</div>
                <div className="otp-input">{value[2]}</div>
                <div className="otp-input">{value[3]}</div>
            </div>
            <div className="d-flex jc">
                <div className="pinpad-wrapper">
                    <button onClick={() => onPress(1)} className='pinpad-btn'>1</button>
                    <button onClick={() => onPress(2)} className='pinpad-btn'>2</button>
                    <button onClick={() => onPress(3)} className='pinpad-btn'>3</button>
                    <button onClick={() => onPress(4)} className='pinpad-btn'>4</button>
                    <button onClick={() => onPress(5)} className='pinpad-btn'>5</button>
                    <button onClick={() => onPress(6)} className='pinpad-btn'>6</button>
                    <button onClick={() => onPress(7)} className='pinpad-btn'>7</button>
                    <button onClick={() => onPress(8)} className='pinpad-btn'>8</button>
                    <button onClick={() => onPress(9)} className='pinpad-btn'>9</button>
                    <button onClick={() => onPress('correct')} className='pinpad-btn correct '>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" height={30} width={30}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z" />
                        </svg>
                    </button>
                    <button onClick={() => onPress(0)} className='pinpad-btn'>0</button>
                    <button onClick={() => handleSubmit()} className='pinpad-btn success'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" height={30} width={30}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>

                    </button>
                </div>
            </div>
        </div>

    )
}

export default CodePin