import React, { useEffect } from 'react'
import UserStore from '../stores/UserStore'
import { observer } from "mobx-react"
import { useNavigate } from 'react-router-dom'

const NotFoundPage = () => {

    const navigate = useNavigate()

    return (

        <div className="card-body d-flex flex-column jc ac text-primary" style={{minHeight : '100vh'}}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" height={130} width={130}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
            <div className="h1 mb-3">Page non trouvé !!</div>
            <button className="btn btn-secondary btn-lg" onClick={() => navigate('/')}>Retour à l'acceuil</button>
        </div>


    )
}

export default NotFoundPage