import React, { useState } from 'react'
import SimpleBar from 'simplebar-react'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import { faCashRegister, faCity, faCubes, faHome, faReceipt, faStore, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserStore from '../stores/UserStore'

const AppMenu = observer((props) => {

    const isNotRestricted = (roles) => {
        let val = UserStore.isNotRestricted(roles)
        console.log(roles, val)
        return val
    }

    const menu = [
        {
            // header: isCampagne ? 'CLIENTS' : 'PILOTER',
            children: [
                {
                    name: 'Tableau de bord',
                    // icon: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
                    link: {
                        pathname: '/dashboard',
                        state: { name: 'Tableau de bord' }

                    },
                },
                {
                    name: 'Mes cartes',
                    // icon: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
                    link: {
                        pathname: '/cards',
                        state: { name: 'Cartes' }

                    },
                },
                {
                    name: 'Transactions',
                    // icon: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
                    link: {
                        pathname: '/transactions',
                        state: { name: 'Transactions' }

                    },
                },
            ]
        }

    ]

    return <>
        <ul className="navbar-nav">
            {
                menu.filter(item => isNotRestricted(item.restrict)).map(item => (
                    <>
                        {
                            item.header && <li className="nav-title">{item.header}</li>
                        }
                        {
                            item.children.filter(item => isNotRestricted(item.restrict)).map(child => (
                                <MenuItem {...child}></MenuItem>
                            ))
                        }
                    </>
                ))
            }
        </ul>
    </>
})

const MenuItem = (props) => {

    const {
        name,
        link,
        children,
        icon,
        _blank
    } = props

    return (
        <>
            {
                children &&
                <SubMenuItem {...props}></SubMenuItem>

            }
            {
                !children &&
                <>
                    {
                        !_blank &&
                        <li className="nav-item">
                            <NavLink to={link} className="nav-link" activeClassName="active">
                                {icon}
                                {name}
                            </NavLink>
                        </li>
                    }
                    {
                        _blank &&
                        <a target="_blank" href={link} className="nav-link">
                            {icon}
                            {/* <FontAwesomeIcon icon={item.icon} className="mr-2"></FontAwesomeIcon> */}
                            {name}
                        </a>
                    }
                </>

            }
        </>
    )


}

const SubMenuItem = (props) => {

    const {
        name,
        children,
        icon
    } = props

    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <li className={classnames('nav-item nav-dropdown', { 'open': menuOpen })}>
            <a className="nav-link nav-dropdown-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                {icon}
                {name}
            </a>
            <ul className="nav-dropdown-items">
                {children.map(item => (
                    <li className="nav-item">

                        <NavLink to={item.link} className="nav-link">
                            {/* <FontAwesomeIcon icon={item.icon} className="mr-2"></FontAwesomeIcon> */}
                            {item.name}
                        </NavLink>

                    </li>

                ))
                }
            </ul>
        </li>
    )
}

export default AppMenu