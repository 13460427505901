export const formatCardNumber = (card) => {
    // console.log(card.toString())
    if (!card)
        return card
    const part1 = card.substring(0, 8)
    const part2 = card.substring(8, 11)
    const part3 = card.substring(11, 14)
    const part4 = card.substring(14, 17)

    return part1 + ' ' + part2 + ' ' + part3 + ' ' + part4

}

export const formatPhoneNumber = (tel) => {
    // console.log(tel.toString())
    if (!tel)
        return tel
    const part1 = tel.substring(0, 4)
    const part2 = tel.substring(4, 6)
    const part3 = tel.substring(6, 8)
    const part4 = tel.substring(8, 10)

    return part1 + ' ' + part2 + ' ' + part3 + ' ' + part4

}

export const getEquivalence = (
    montant,
    typeTransac,
    commercant
) => {
    console.log(montant)
    montant = montant.toString().replace(',', '.')

    if ([1, 5].includes(typeTransac)) {
        console.log('FDFDFDDFDF', parseInt(commercant.AvecConversionAchatPME1))
        if (parseInt(commercant.AvecConversionAchatPME1)) {
            const conversionRate = commercant.YConversionEuroAchatPME1 / commercant.XConversionEuroAchatPME1;
            const montantOut = parseFloat(conversionRate * montant, 2).toFixed(2);
            return <span>Soit  <b>{montantOut.replace('.', ',')}</b> {(parseFloat(montantOut) == 1 ? commercant.NomUnitePME1 + ' crédité' : commercant.NomUnitesPME1 + ' crédités')} sur la carte du client</span>
        }
        else {
            const montantOut = parseFloat(montant, 2).toFixed(2);
            return <span>Soit  <b>{montantOut.replace('.', ',')}</b> {(parseFloat(montantOut) == 1 ? commercant.NomUnitePME1 + ' crédité' : commercant.NomUnitesPME1 + ' crédités')} sur la carte du client</span>
        }

    }

    if ([3].includes(typeTransac)) {
        if (parseInt(commercant.AvecConversionAchatPME2)) {
            const conversionRate = commercant.YConversionEuroAchatPME2 / commercant.XConversionEuroAchatPME2;
            const montantOut = parseFloat(conversionRate * montant, 2).toFixed(2);
            return <span>Soit  <b>{montantOut.replace('.', ',')}</b> {(parseFloat(montantOut) == 1 ? commercant.NomUnitePME2 + ' crédité' : commercant.NomUnitesPME2 + ' crédités')} sur la carte du client</span>
        }
        else {
            const montantOut = parseFloat(montant, 2).toFixed(2);
            return <span>Soit  <b>{montantOut.replace('.', ',')}</b> {(parseFloat(montantOut) == 1 ? commercant.NomUnitePME2 + ' crédité' : commercant.NomUnitesPME2 + ' crédités')} sur la carte du client</span>
        }
    }


    if ([2].includes(typeTransac)) {

        const montantOut = parseFloat(montant, 2).toFixed(2);
        return <span>Soit  <b>{montantOut.replace('.', ',')}</b> {(parseFloat(montantOut) == 1 ? commercant.NomUnitePME1 + ' débité' : commercant.NomUnitesPME1 + ' débités')} sur la carte du client</span>

    }

    if ([4].includes(typeTransac)) {

        const montantOut = parseFloat(montant, 2).toFixed(2);
        return <span>Soit  <b>{montantOut.replace('.', ',')}</b> {(parseFloat(montantOut) == 1 ? commercant.NomUnitePME2 + ' débité' : commercant.NomUnitesPME2 + ' débités')} sur la carte du client</span>

    }



    return ''
}

export const currencyFormat = (n) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(!n ? 0 : n)
export const numberFormat = (n) => new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 2 }).format(!n ? 0 : n)