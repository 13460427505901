import React, { useState, useEffect } from "react";

import { Route, Routes } from "react-router-dom";
import MainPage from "../pages/MainPage";
import LogoutPage from "../pages/LogoutPage";
import IsAuthenticated from "../middlewares/IsAuthenticated";
import LoginPage from "../pages/LoginPage";
import IsNotAuthenticated from "../middlewares/IsNotAuthenticated";
import UserStore from "../stores/UserStore";
import RegisterPage from "../pages/RegisterPage";
import SlideRoutes from 'react-slide-routes';
import { observer } from "mobx-react";
import NewCardPage from "../pages/NewCardPage";
import NotFoundPage from "../pages/NotFoundPage";
import NavbarLayout from "./NavbarLayout";




const AppRoute = observer(() => {

  const [ready, setReady] = useState(false)

  useEffect(() => {


    async function doAsync() {
      await UserStore.init()
      setReady(true)
    }
    doAsync()
  }, [])

  return (


    <Routes>
      <Route path='*' element={<NotFoundPage />} />
      <Route exact path="/" element={<IsAuthenticated><NavbarLayout><MainPage /></NavbarLayout></IsAuthenticated>} />
      <Route exact path="/logout" element={<IsAuthenticated><LogoutPage /></IsAuthenticated>} />
      <Route exact path="/login" element={<IsNotAuthenticated><LoginPage /></IsNotAuthenticated>} />
      <Route exact path="/register" element={<IsNotAuthenticated><RegisterPage/></IsNotAuthenticated>} />
      <Route path="/n/:token/:smsCode?" element={<NewCardPage/>} />
    </Routes>


  );
});

export default AppRoute;
