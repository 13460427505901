//import AppRouteAnimated from "./components/AppRouteAnimated";
import AppRoute from "./components/AppRoute";
import { BrowserRouter } from 'react-router-dom';

const App = () =>
(
  <BrowserRouter>
    <AppRoute></AppRoute>
  </BrowserRouter>
);


export default App;
