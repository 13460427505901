import { observable } from 'mobx'
import api from '../utils/api'
import modalAlert from '../components/Alert'
import LoadingStore from './LoadingStore'

const props = {
    user: {

    },
    successMobileRegister: false,
    emailToRegister: null,
    resetPasswordIsOk: null,
    accountActivated: null,
    loading: false
}

const UserStore = {

    props: props,

    /***** Methods ******/
    async init() {
        LoadingStore.on()
        console.log('init User')
        const session = JSON.parse(localStorage.getItem(process.env['REACT_APP_LOCAL_STORAGE_KEY']))
        console.log(session)
        if (session && session.access_token) {
            // this.props.user = session
            await this.whoami()
            LoadingStore.off()
            return true
            // window.user = this
            // CampagneStore.getAllStatutCampagne(session.idCompteSMS)
        } else {
            LoadingStore.off()
            return false
        }
    },
    async whoami() {
        console.log('whoami')
        LoadingStore.on()
        const res = await api.post('', { action: 'me' })
        if (res && res.data && res.data.success) {
            res.data.user.isAuthenticated = true
            this.props.user = res.data.user
            // console.log(res.data.user)
            // localStorage.setItem('fidlink_api_user', JSON.stringify(res.data.user))
        }
        else if (typeof res.data.success == 'boolean') {
            this.props.user = {}
        }


        LoadingStore.off()
    },
    async register(values) {
        LoadingStore.on()
        const res = await api.post('/register', values)
        if (res && res.data.success) {
            console.log('REGISTER_SUCCESS')
            localStorage.setItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'], JSON.stringify({
                access_token: res.data.access_token,
                refresh_token: res.data.refresh_token,
            }))
            await this.whoami()
            return res.data.card

        }
        if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Echec',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }

        LoadingStore.off()
    },
    async generateNewCard(values) {
        LoadingStore.on()
        const res = await api.post('/generateNewCard', values)
        if (res && res.data.success) {
            console.log('NEW_CARD_SUCCESS')
            LoadingStore.off()
            return res.data.card
        }
        if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }

        LoadingStore.off()
    },
    async resendActivationCode(values) {
        LoadingStore.on()
        const res = await api.post('/resendActivationCode', values)
        if (res && res.data.success) {
            console.log('RESEND_CODE_SUCCESS')
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: 'Le code a bien été envoyé'
            })
            LoadingStore.off()
            return true
        }
        if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }

        LoadingStore.off()
    },
    async activateCard(values) {
        LoadingStore.on()
        const res = await api.post('/activateCard', values)
        if (res && res.data.success) {
            console.log('ACTIVATE_CARD_SUCCESS')
            LoadingStore.off()
            return res.data.success
        }
        if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }

        LoadingStore.off()
    },
    async checkIfAccountExists(email) {
        console.log('checkIfAccountExists')
        LoadingStore.on()
        const res = await api.post('', { 
            email : email,
            action: 'checkIfAccountExists'
         })
        if (res && res.data && res.data.success) {
            LoadingStore.off()
            return res.data.userAlreadyExists
        }
        else if (typeof res.data.success == 'boolean') {

            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }

        LoadingStore.off()
    },
    async createCardIfNotExists(token) {
        console.log('createCardIfNotExists')
        LoadingStore.on()
        const res = await api.post('', { 
            token : token,
            action: 'createCardIfNotExists'
         })
        if (res && res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {

            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }

        LoadingStore.off()
    },
    async login(values) {
        LoadingStore.on()
        const res = await api.post('', values)
        if (res && res.data.success) {
            console.log('LOGIN_SUCCESS')
            console.log('store', process.env['REACT_APP_LOCAL_STORAGE_KEY'])
            localStorage.setItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'], JSON.stringify({
                access_token: res.data.access_token,
                refresh_token: res.data.access_token,
            }))
            // if (window.ReactNativeWebView) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
            //     window.ReactNativeWebView.postMessage(JSON.stringify({...values,access_token : res.data.access_token}))
            // }
            await this.whoami()

            this.props.user.isAuthenticated = true

        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }
        LoadingStore.off()
    },
    async logout() {
        console.log('Logout user')
        localStorage.removeItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'])
        this.reset()
    },
    isLoggedIn() {
        return this.props.user.isAuthenticated
    },
    isNotRestricted(roles) {
        if (roles)
            return !roles.includes(this.props.user.role_id)
        return true
    },
    reset() {
        this.props = props
    }
}

export default observable(UserStore)