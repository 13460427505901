import React, { useState } from "react";
import { observer } from "mobx-react";
import myYup from "../utils/myYup";
import { useFormik } from "formik";
import Input from "../components/Input";
import { Check, CheckCircle, Feather, UserCheck } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import UserStore from "../stores/UserStore";
import CodePin from "../components/CodePin";
import { formatCardNumber } from "../utils/utils";
import logo from '../assets/img/logo_side_fidlink_bleu.png'
import { useEffect } from "react";
import CardStore from "../stores/CardStore";
import modalAlert from "../components/Alert";
import { Modal, ModalBody } from "reactstrap";


const NewCardPage = observer((props) => {

    const [modeAuth, setModeAuth] = useState('login')

    const [accountIsOk, setAccountIsOk] = useState(false)
    const [activated, setActivated] = useState(false)
    const [activateOnly, setActivateOnly] = useState(false)
    const [numCard, setNumCard] = useState(null)
    const [cardAlreadyExists, setCardAlreadyExists] = useState(false)
    const [isLastStep, setIsLastStep] = useState(false)

    const [isOpenEditPhoneModal, setIsOpenEditPhoneModal] = useState(false)
    const [code, setCode] = useState('')
    const { token, smsCode } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        const init = async () => {
            let res = null
            if (!activated)
                res = await CardStore.checkIfTokenExists(token)
            if (!res) {
                await modalAlert({
                    title: 'Erreur',
                    typeAlert: 'danger',
                    text: "Enseigne introuvable"
                })
                navigate('/wrongToken')
            }

            if(res.success){
                register.setFieldValue('tel',res.tel)
            }

            if (res.numCarte) {
                setAccountIsOk(true)
                setActivateOnly(true)
                setNumCard(res.numCarte)
                if (smsCode)
                    setCode(smsCode)
            }

        }

        init()
    }, [])


    const register = useFormik({
        initialValues: {
            action: 'register',
            token: token,
            gender: null,
            type: null,
            email: '',
            nom: '',
            prenom: '',
            tel: '',
            date_naiss: '',
            cp: '',
            ville: '',
            pass: '',
            c_pass: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: myYup.object().shape({
            gender: myYup.number().required().nullable(true),
            nom: myYup.string().required(),
            prenom: myYup.string().required(),
            email: myYup.string().email().required(),
            date_naiss: myYup.string().date().required(),
            tel: myYup.string().required().matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres")
                .min(10, 'Le numéro doit contenir 10 chiffres')
                .max(10, 'Le numéro doit contenir 10 chiffres'),
            cp: myYup.string().required().matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres"),
            ville: myYup.string().required(),
            adresse: myYup.string().required(),
            pass: myYup.string().required(),
            c_pass: myYup.string()
                .label('confirmation mot de passe')
                .required()
                .oneOf([myYup.ref('pass'), null], 'Les mots de passe ne correspondent pas.'),
        }),
        onSubmit: async (values) => {
            const res = await UserStore.register(values)

        },
    });

    const login = useFormik({
        initialValues: {
            action: 'login',
            email: 'joel.panor@gmail.com',
            pass: '14071990',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: myYup.object().shape({
            email: myYup.string().email().required(),
            pass: myYup.string().required(),
        }),
        onSubmit: async (values) => {
            console.log('loginForm')
            const res = await UserStore.login(values)
        },
    });


    const confirmAccount = async () => {

        const cardState = await UserStore.createCardIfNotExists(token)

        setAccountIsOk(true)
        console.log(cardState)
        if (cardState.success) {
            if (cardState.alreadyHasCard) {
                setCardAlreadyExists(true)
                setNumCard(cardState.numCard)
            }
            if (cardState.dateActivation)
                setActivated(true)
            if (cardState.card)
                setNumCard(cardState.card)
        }

    }

    const submitCode = async () => {
        let data = {
            action: 'activateCard',
            NumCarte: numCard,
            code: code,
            token: token
        }

        const res = await UserStore.activateCard(data)

        if (res) {
            setIsLastStep(true)
            setActivated(true)
        }else{
            setCode('')
        }

    }

    const resendCode = async (tel) => {
        let data = {
            action: 'resendActivationCode',
            NumCarte: numCard,
            newTel: tel,
            token: token
        }

        const res = await UserStore.resendActivationCode(data)

        if (res.success)
            setActivated(true)

    }

    const edit = async () => {
        setActivated(true)
    }

    return (
        <div className="d-flex flex-column jc ac pt-3">

            <div className="main-box w-100 px-3 d-flex ac jc" style={{ minHeight: "90vh" }}>
                <div className="card w-100">
                    {/* <div className="progress-steps d-flex justify-content-between card-body  mt-1 mb-1">
                        {
                            steps.map((item, index) =>
                                <div key={index} className="text-sm d-flex ac" style={{ fontSize: '.9rem' }}>
                                    {
                                        item.isCheked &&
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" width={20} height={20} className="text-success me-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    }

                                    <span className="fw-bold">{item.name}</span>
                                </div>)
                        }
                    </div> */}
                    <div className="d-flex w-100 ac jc">
                        <img src={logo} alt="" className="header-logo mb-3" />
                    </div>
                    {!UserStore.props.user.isAuthenticated && modeAuth == 'login' && !activateOnly &&
                        <div className="card-body w-100">
                            <h4 className="">Connexion compte FIDLINK</h4>
                            <p className="">Afin de créer votre carte, veuillez vous connecter ou créer votre compte.</p>
                            <div className="form-group floated mb-2">
                                <label>Email</label>
                                <Input
                                    name="email"
                                    type="email"
                                    onChange={login.setFieldValue}
                                    value={login.values["email"]}
                                    className={"form-control"}
                                    error={login.errors["email"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Mot de passe</label>
                                <Input
                                    name="pass"
                                    type="password"
                                    onChange={login.setFieldValue}
                                    value={login.values["pass"]}
                                    className={"form-control"}
                                    error={login.errors["pass"]}
                                />
                            </div>
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={login.handleSubmit}
                                >
                                    <span className="me-2">Se connecter</span>

                                </button>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12 h4 mb-0 text-center ">
                                    ou
                                </div>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12">
                                    <button onClick={() => setModeAuth('register')} className="btn btn-secondary w-100">
                                        <span className="ms-2">Créer mon compte</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {!UserStore.props.user.isAuthenticated && modeAuth == 'register' && !activateOnly &&
                        <div className="card-body w-100">
                            <h4 className="">Création compte</h4>
                            <p className="">Veuillez compléter le formulaire ci-dessous afin de créer votre compte FIDLINK</p>
                            <div className="d-flex mb-2 jc">
                                <div className="form-group  me-5">
                                    <Input
                                        name="gender"
                                        id={"gender1"}
                                        label="Monsieur"
                                        type="radio"
                                        onChange={() => register.setFieldValue('gender', 1)}
                                        isChecked={register.values["gender"] == 1}
                                        value={1}
                                        className={"form-control"}
                                        error={register.errors["gender"]}
                                    />
                                </div>
                                <div className="ms-5  form-group">
                                    <Input
                                        name="gender"
                                        id={"gender2"}
                                        label="Madame"
                                        type="radio"
                                        onChange={() => register.setFieldValue('gender', 2)}
                                        isChecked={register.values["gender"] == 2}
                                        value={2}
                                        className={"form-control"}
                                        error={register.errors["gender"]}
                                    />
                                </div>
                            </div>
                            {
                                !!register.errors["gender"] &&
                                <div className="w-100 invalid-feed mb-2">
                                    {register.errors["gender"]}
                                </div>
                            }
                            <div className="form-group floated mb-2">
                                <label>Nom</label>
                                <Input
                                    name="nom"
                                    type="text"
                                    onChange={register.setFieldValue}
                                    value={register.values["nom"]}
                                    className={"form-control"}
                                    error={register.errors["nom"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Prénom</label>
                                <Input
                                    name="prenom"
                                    type="text"
                                    onChange={register.setFieldValue}
                                    value={register.values["prenom"]}
                                    className={"form-control"}
                                    error={register.errors["prenom"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Email</label>
                                <Input
                                    name="email"
                                    type="email"
                                    onChange={register.setFieldValue}
                                    value={register.values["email"]}
                                    className={"form-control"}
                                    error={register.errors["email"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <Input
                                    label="Date de naissance"
                                    name="date_naiss"
                                    type="dateDropdown"
                                    onChange={register.setFieldValue}
                                    value={register.values["date_naiss"]}
                                    className={"form-control"}
                                    error={register.errors["date_naiss"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>N° Mobile</label>
                                <Input
                                    name="tel"
                                    type="text"
                                    onChange={register.setFieldValue}
                                    value={register.values["tel"]}
                                    className={"form-control"}
                                    error={register.errors["tel"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Adresse</label>
                                <Input
                                    name="adresse"
                                    type="text"
                                    onChange={register.setFieldValue}
                                    value={register.values["adresse"]}
                                    className={"form-control"}
                                    error={register.errors["adresse"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Code postal</label>
                                <Input
                                    name="cp"
                                    type="text"
                                    onChange={register.setFieldValue}
                                    value={register.values["cp"]}
                                    className={"form-control"}
                                    error={register.errors["cp"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Ville</label>
                                <Input
                                    name="ville"
                                    type="text"
                                    onChange={register.setFieldValue}
                                    value={register.values["ville"]}
                                    className={"form-control"}
                                    error={register.errors["ville"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Mot de passe</label>
                                <Input
                                    name="pass"
                                    type="password"
                                    onChange={register.setFieldValue}
                                    value={register.values["pass"]}
                                    className={"form-control"}
                                    error={register.errors["pass"]}
                                />
                            </div>
                            <div className="form-group floated mb-2">
                                <label>Confirmation mot de passe</label>
                                <Input
                                    name="c_pass"
                                    type="password"
                                    onChange={register.setFieldValue}
                                    value={register.values["c_pass"]}
                                    className={"form-control"}
                                    error={register.errors["c_pass"]}
                                />
                            </div>
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={register.handleSubmit}
                                >
                                    <span className="me-2">Valider</span>

                                </button>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12 h4 mb-0 text-center">
                                    ou
                                </div>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12">
                                    <button onClick={() => setModeAuth('login')} className="btn btn-secondary w-100">
                                        <span className="ms-2">J'ai déjà un compte</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        UserStore.props.user.isAuthenticated && !accountIsOk && !activateOnly &&
                        <div className="card-body d-flex flex-column ac  w-100">
                            <UserCheck className="text-success" size={70}></UserCheck>
                            <p className="">Connecté(e) en tant que :</p>
                            <h4 className="mt-3 ">{UserStore.props.user.prenom} {UserStore.props.user.nom}</h4>
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={() => confirmAccount()}
                                >
                                    <span className="me-2">Continuer</span>

                                </button>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12 h4 mb-0 text-center ">
                                    ou
                                </div>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12">
                                    <button onClick={() => UserStore.logout()} className="btn btn-secondary w-100">
                                        <span className="ms-2">Se déconnecter</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        UserStore.props.user.isAuthenticated && accountIsOk && cardAlreadyExists && activated && !isLastStep &&
                        <div className="card-body d-flex flex-column ac">
                            <CheckCircle className="text-success" size={70}></CheckCircle>
                            <p className="">Vous possédez déjà une carte pour cette enseigne.</p>
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={() => navigate('/')}
                                >
                                    <span className="me-2">Accéder à mon espace</span>

                                </button>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12 h4 mb-0 text-center ">
                                    ou
                                </div>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12">
                                    <button onClick={() => UserStore.logout()} className="btn btn-secondary w-100">
                                        <span className="ms-2">Se déconnecter</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        numCard && !activated &&
                        <div className="card-body">
                            <h4 className="">Saisie code SMS</h4>
                            <CodePin
                                value={code}
                                setValue={val => setCode(val)}
                                handleSubmit={() => submitCode()}
                                resend={() => resendCode()}
                                edit={() => setIsOpenEditPhoneModal(true)}
                            ></CodePin>

                        </div>
                    }
                    {
                        activated && isLastStep &&
                        <div className="card-body d-flex flex-column ac">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-success" width={150} height={150}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                            </svg>
                            <h4 className="mt-3 ">Félicitations</h4>
                            <p className="h5">Votre carte</p>
                            <p className="h5">{formatCardNumber(numCard)}</p>
                            <p className="h5">a été activée</p>
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={() => navigate('/')}
                                >
                                    <span className="me-2">Accéder à mon espace</span>

                                </button>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12 h4 mb-0 text-center ">
                                    ou
                                </div>
                            </div>
                            <div className="w-100 d-flex  mt-3">
                                <div className="col-12">
                                    <button onClick={() => UserStore.logout()} className="btn btn-secondary w-100">
                                        <span className="ms-2">Se déconnecter</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {
                isOpenEditPhoneModal &&
                <EditPhoneModal
                    isOpen={isOpenEditPhoneModal}
                    toggle={() => setIsOpenEditPhoneModal()}
                    submit={(tel) => resendCode(tel)}
                ></EditPhoneModal>
            }
        </div>
    )
})

export default NewCardPage

const EditPhoneModal = observer((props) => {

    const navigate = useNavigate()
    const { isOpen, toggle, submit } = props
    const [tel, setTel] = useState()


    const onSubmit = async () => {

        const telSchema = myYup.object().shape({
            tel: myYup.string()
                .required('Le numéro de téléphone est requis')
                .matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres")
                .min(10, 'Le numéro doit contenir 10 chiffres')
                .max(10, 'Le numéro doit contenir 10 chiffres')
        })

        try {
            const validation = await telSchema.validate({ tel: tel })
            submit(tel)
            toggle()
        }
        catch (err) {
            // console.log(err.message)
            return modalAlert({
                title: 'Attention',
                text: err.message ? err.message : '',
                typeAlert: 'warning'
            })
        }

    }

    return (

        <Modal isOpen={isOpen} centered={true} >
            {
                isOpen &&
                <ModalBody className="px-3 py-3">
                    <div className="d-flex flex-column w-100 ac jc">
                        <div className="mt-3 d-flex flex-column w-100">
                            <h4>Modification n° téléphone</h4>
                            <p>Veuillez saisir votre numéro de téléphone</p>
                        </div>
                        <div className="form-group floated mb-3 w-100">
                            <label>N° mobile</label>
                            <Input
                                name="tel"
                                type="text"
                                onChange={(name, val) => setTel(val)}
                                value={tel}
                                className={"form-control"}
                                error={false}
                            ></Input>
                        </div>
                        <div className="mt-3 d-flex flex-column ac jc w-100">
                            <button onClick={() => onSubmit()} className="btn btn-primary  w-100">Valider</button>
                        </div>
                        <div className="mt-3 d-flex flex-column ac jc w-100">
                            <button onClick={() => toggle()} className="btn btn-secondary  w-100">Fermer</button>
                        </div>
                    </div>


                </ModalBody>
            }
        </Modal >
    )
})