import React, { useEffect } from 'react'
import UserStore from '../stores/UserStore'
import { observer } from "mobx-react"

const LogoutPage = (props) => {

    useEffect(() => {
        setTimeout(() => {
            UserStore.logout()

        }, 2000)
    }, [])

    return (

        <div className="d-flex ac jc" style={{ height: '90vh' }}>
            <div className="card">
                <div className="card-body">
                    <div className="card-body d-flex flex-column jc ac">
                        <div className="hollow-dots-spinner " >
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="loading-text">Déconnexion en cours ...</div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default observer(LogoutPage)