import { observable, set, toJS } from 'mobx'
import api from '../utils/api'
import modalAlert, { modalInfo } from '../components/Alert'


import moment from 'moment/moment'
import { number } from '../utils/numeral'
import LoadingStore from './LoadingStore'


const props = {
}



const CardStore = observable({
    /***** Props ******/
    props: props,

    /***** Methods ******/

    async checkIfTokenExists(token) {
        const res = await api.post('', {
            action: 'checkIfTokenExists',
            token: token,
        })

        if (res.data && res.data.success) {
            return res.data
        }
        else{
            return false
        }
    }

})

export default CardStore