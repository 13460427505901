import axios from 'axios'
import UserStore from '../stores/UserStore';
import modalAlert from '../components/Alert'
import LoadingStore from '../stores/LoadingStore';

const instance = axios.create({
    baseURL: process.env['REACT_APP_API_URL'],
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    config => {
        const session = localStorage.getItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'])
        if (session) {
            const user = JSON.parse(session)
            if (user.access_token) {
                config.headers['Authorization'] = 'Bearer ' + user.access_token
            }
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);



let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    console.log('faildsd', failedQueue)
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

instance.interceptors.response.use(function (response) {
    return response;
}, async (error) => {

    const originalRequest = error.config;
    // alert(error.response.status)
    switch (error.response.status) {

        case 401:
            // alert('unauthenticated')
            // alert('error 419')
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return instance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                })
            }

            originalRequest._retry = true;
            isRefreshing = true;


            return new Promise(function (resolve, reject) {

                let session = localStorage.getItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'])
                let headers = {}
                let user = {}
                if (session) {
                    user = JSON.parse(session)
                    if (user.access_token) {
                        headers = {
                            'Authorization': 'Bearer ' + user.access_token,
                        }
                    }
                }

                axios.post(process.env['REACT_APP_API_URL'] + '/refreshToken', {
                    action : 'refreshToken',
                    access_token: user.access_token,
                    refresh_token: user.refresh_token,
                }, { headers })
                    .then((res) => {
                        console.log(res)
                        user.access_token = res.data.access_token
                        user.refresh_token = res.data.refresh_token
                        localStorage.setItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'], JSON.stringify(user));

                        originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access_token;
                        // console.log(qs.parse(originalRequest.data))
                        // originalRequest.data = qs.parse(originalRequest.data)
                        processQueue(null, res.data.token);

                        resolve(instance(originalRequest));
                    })
                    .catch(async (err) => {
                        failedQueue = [];
                        // alert('Votre session a expiré')
                        // processQueue(err, null);
                        await modalAlert({
                            title: 'Info',
                            typeAlert: 'info',
                            text: "Votre session a expirée"
                        })
                        UserStore.logout('')
                        LoadingStore.reset()
                        // history.push('/login')
                        reject(err);
                    })
                    .then(() => { console.log('then'); isRefreshing = false })
            })
        case 402:
            await modalAlert({
                title: 'Info',
                typeAlert: 'info',
                text: "Votre session a expirée"
            })
            UserStore.logout('')
            LoadingStore.reset()
            return error.response;
        case 406:
            await modalAlert({
                title: 'Info',
                typeAlert: 'info',
                text: "Token manquant"
            })
            await UserStore.logout()
            LoadingStore.reset()
            return error.response;

        case 403:
            await modalAlert({
                title: 'Error',
                typeAlert: 'danger',
                text: "Vous n'avez pas les dorits"
            })
            return error.response;

        case 405:
            return alert('error 405')

        case 419:



        case 422:
            return error.response

        default:
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            return Promise.reject(error);

    }

});



export default instance;