import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { unmountComponentAtNode, render } from 'react-dom';
import ReactDOM from 'react-dom/client';
import parse from 'html-react-parser';
import { AlertTriangle, CheckCircle, HelpCircle, Info, XCircle } from 'react-feather';

const ModalAlert = (props) => {

    const { title,no_icon, typeAlert, text, component, size, close } = props

    const [open, setOpen] = useState(true);

    return (
        <Modal backdrop="static" zIndex={10000000000000000} size={size ? size : 'sm'} centered={true} isOpen={open} toggle={() => setOpen(!open)} >
            <ModalBody>
                <h5 className={'text-' + typeAlert}>{title}</h5>
                <div className="row">
                    <div className="col-auto d-flex jc ac jc">
                        {
                            !!!no_icon &&

                            <p>
                                {
                                    typeAlert == 'info' &&
                                    <Info size="50" className="text-info"></Info>
                                }
                                {
                                    typeAlert == 'success' &&
                                    <CheckCircle size="50" className="text-success"></CheckCircle>
                                }
                                {
                                    typeAlert == 'danger' &&
                                    <XCircle size="50" className="text-danger"></XCircle>
                                }
                                {
                                    typeAlert == 'warning' &&
                                    <AlertTriangle size="50" className="text-warning"></AlertTriangle>
                                }
                                {
                                    typeAlert == 'question' &&
                                    <HelpCircle size="50" className="text-info"></HelpCircle>
                                }
                            </p>
                        }
                    </div>
                    <div className="col d-flex ac">
                        {
                            text && <p>{parse(text)}</p>
                        }
                        {
                            component && component
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button className={"btn btn-sm btn-" + typeAlert} onClick={() => { setOpen(!open); close(false) }}>Fermer</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

const ModalInfo = (props) => {

    const { title, typeAlert, component, size, close } = props

    const [open, setOpen] = useState(true);

    return (
        <Modal backdrop="static" zIndex={10000000000000000} size={size ? size : 'sm'} centered={true} isOpen={open} toggle={() => setOpen(!open)} >
            <ModalBody>
                <h5 className={'text-' + typeAlert}>{title}</h5>
                <div className="w-100">
                        {
                            component && component
                        }
                    </div>
            
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button className={"btn btn-sm btn-" + typeAlert} onClick={() => { setOpen(!open); close(false) }}>Fermer</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}


const modalAlert = (props) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = result => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };

        render(<ModalAlert {...props} close={handleResolve} />, el);
    });
};

export const modalInfo = (props) => {
    return new Promise(resolve => {

        let el = document.createElement('div');
        const root = ReactDOM.createRoot(el);
        const handleResolve = result => {
            // unmountComponentAtNode(el);
            root.unmount();
            el = null;
            resolve(result);
        };

        root.render(<ModalInfo {...props} close={handleResolve} />);
    });
};

export default modalAlert
