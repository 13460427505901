import React from 'react'
import { useFormik } from 'formik'
import { Lock, Mail } from 'react-feather'
import Input from '../components/Input'
import UserStore from '../stores/UserStore'
import { Link, useNavigate } from "react-router-dom";
import { observer } from 'mobx-react';
import logo from "../assets/img/logo_fidlink_bleu.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

const LoginPage = observer((props) => {

    const navigate = useNavigate();

    const redirectIfLoginSuccess = () => navigate({
        pathname: '/dashboard',
        state: { name: 'Tableau de bord' }

    })

    const goToRegister = () => navigate({
        pathname: '/register',
    })


    const { errors, values, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            action: 'login',
            email: 'joel.panor@gmail.com',
            pass: '14071990'
        },
        onSubmit: values => UserStore.login(values, redirectIfLoginSuccess),
        validateOnBlur: false,
        validateOnChange: false
    })

    return (
        <div className="hold-transition login-page ac jc" style={{ minHeight: "90vh" }}>
            <div className="login-box  px-2" style={{ marginTop: "5rem" }}>
                <div className="card">
                    <div className="card-body">
                        <div className="login-logo d-flex ac jc text-white mb-4">
                            <img
                                src={logo}
                                alt=""
                                height="80"
                                className="d-inline-block align-text-top"
                            />
                        </div>
                        <div className="form-group floated mb-3">
                            <label>Email</label>
                            <Input
                                name="email"
                                type="text"
                                className="form-control"
                                onChange={setFieldValue}
                                value={values['email']}
                                error={errors['email']}
                            />
                        </div>
                        <div className="form-group floated mb-3">
                            <label>Mot de passe</label>
                            <Input
                                name="pass"
                                type="password"
                                className="form-control"
                                onChange={setFieldValue}
                                value={values['pass']}
                                error={errors['pass']}
                            />
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <button onClick={handleSubmit} className="btn btn-secondary w-100">Se connecter</button>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 h4 mb-0 text-center">
                                ou
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <button onClick={() => goToRegister()} className="btn btn-secondary w-100">
                                    <span className="ms-2">Créer mon compte</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
})

export default LoginPage